<template>
  <div class="hello">
    <div class="one-box">
      <div class="one-box-left">
        <div class="title">优智心谱，一站式心理行业解决方案专家</div>
        <div class="subTitle">
          现在预约，了解方案详细内容、报价与实施服务，专属顾问1个工作日内为您解答
        </div>
      </div>

      <div class="btn">立即咨询定制</div>
    </div>
    <div class="flex flex-sa">
      <div class="logo-box flex">
        <div class="flex flex-item-center">
          <div class="logo">
            <img
              class="logo-img"
              src="../../../assets/img/common/logo.png"
              alt=""
            />
          </div>
          <div class="mobile">
            <div class="mobile-icon">
              <img
                class="phone-img"
                src="../../../assets/img/common/dibuPhone.png"
                alt=""
              />
            </div>
            <div>售前顾问：<span class="num">178 0653 8105</span></div>
          </div>
          <div class="join">市场商务合作：biz@mob-vision.com</div>
        </div>
      </div>
      <div class="placeholder"></div>
    </div>
    <div class="flex flex-sa copylight-box">
      <div class="copylight">
        优智心谱｜互动视界旗下定制心理平台解决方案品牌 ©
        广州市互动视界网络有限公司 版权所有 <a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备2022026842号-2</a>
      </div>
      <div class="placeholder"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      msg: "底部",
    };
  },
};
</script>

<style scoped lang="scss">
.placeholder {
  width: 100px;
}

.copylight-box {
  background: rgba(244, 244, 244, 1);
}

.one-box {
  padding: 50px 0;
  box-sizing: border-box;
  height: 280px;
  opacity: 1;

  background: rgba(55, 117, 255, 1);

  display: flex;
  align-items: center;
  justify-content: space-around;

  .one-box-left {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    // text-align: center;
    vertical-align: top;

    // align-items: center;
  }
  .subTitle {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: top;
    margin-top: 14px;
  }
  .btn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 48px;
    color: rgba(55, 117, 255, 1);
    text-align: center;
    width: 150px;
    height: 48px;
    opacity: 1;
    border-radius: 24px;
    background: rgba(255, 255, 255, 1);
  }
}
.yuan {
  width: 8px;
  height: 8px;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  display: inline-block;
  margin: 0 5px 0 15px;
  border-radius: 50%;
}

.copylight {
  height: 48px;
  background: rgba(244, 244, 244, 1);
  // padding-left: 120px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 48px;
  color: rgba(153, 153, 153, 1);
  vertical-align: top;
  text-align: left;
}

.logo-box {
  // padding: 0 120px;
  height: 60px;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  .logo {
    margin-right: 80px;
    .logo-img {
      width: 120px;
      height: 35px;
    }
  }

  .mobile {
    display: flex;
    align-items: center;

    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(102, 102, 102, 1);

    .mobile-icon {
      margin-right: 10px;
      .phone-img {
        width: 12px;
        height: 12px;
      }
    }
    .num {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 16.71px;
      color: rgba(55, 117, 255, 1);
    }
  }
  .join {
    margin-left: 80px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(102, 102, 102, 1);
  }
}
</style>
