<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import url("assets/common.css");
body {
  margin: 0px !important;
}
</style>
