<template>
  <div>
    <!--  @close="close" @open="open" -->
    <el-dialog :visible.sync="dialogTableVisible" width="400px">
      <div class="dialog flex-col-center">
        <!-- <div class="dialog-text">扫码添加微信获取更多详情</div> -->

        <img class="dialog-img" src="../../assets/img/common/kefu.png" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "atlDialog",
  props: {
    // dialogTableVisible: {
    //   type: Boolean,
    //   default: false,
    // },
  },

  data() {
    return {
      dialogTableVisible: false,
    };
  },
  methods: {
    close() {
      this.dialogTableVisible = false;
    },
    open() {
      this.dialogTableVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog {
  .dialog-text {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 22.4px;
    color: rgba(34, 34, 34, 1);
    text-align: center;
    margin-bottom: 30px;
  }
  padding: 30px;
  .dialog-img {
    width: 200px;
    height: 200px;
  }
}
</style>
